import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { macroCondition, isTesting } from '@embroider/macros';
import { A } from '@ember/array';
export default class StreamMonitor {
  /* These services the consuming apps. This architecture is a little silly */
  @service socket;
  @service profile;
  /* ---------------------------------------------------------------------- */

  @service stereo;
  @service store;

  @tracked cableUrl;
  @tracked stationUid;
  @tracked stationId;
  @tracked streamId;

  @tracked streamUrl;
  @tracked fallbackUrls;
  @tracked station;
  @tracked onUpdate;
  @tracked lastCacheKey;

  constructor({
    stationUid,
    stationId,
    streamId,
    cableUrl,
    onUpdate = () => {},
  }) {
    this.stationUid = stationUid;
    this.stationId = stationId;
    this.streamId = streamId;
    this.cableUrl = cableUrl;
    this.onUpdate = onUpdate;
  }

  @task
  *start() {
    try {
      yield this.fetchNowPlayingData.perform();
      yield this.loadCable();
    } catch (e) {
      //no op
    }
    this.loop.perform();
  }

  @task
  *loop() {
    if (macroCondition(isTesting())) {
      return;
    }
    while (true) {
      try {
        if (this.isLoaded && !this.cableConnection?.connected) {
          yield timeout(10000);
          yield this.fetchNowPlayingData.perform();
        } else if (this.isLoaded && this.cableConnection?.connected) {
          yield timeout(60000);
          yield this.fetchNowPlayingData.perform();
        } else {
          yield timeout(1000);
        }
      } catch (e) {
        //no op
        yield timeout(10000);
      }
    }
  }

  @task({ drop: true })
  *fetchNowPlayingData() {
    let stationUid =
      this.stationUid ||
      this.store.peekRecord('station', this.stationId)?.station?.uid;

    let nowPlaying = yield this.store
      .query('now-playing', {
        stationId: stationUid || this.stationId,
        streamId: this.streamId,
        cache: this.lastCacheKey,
      })
      .catch((e) => {
        console.error(e);
      });

    // this.streamsData = nowPlaying.streams;
    if (nowPlaying) {
      this.isLoaded = true;
      yield this.updateNowPlaying.perform(nowPlaying);
    }
  }

  loadCable() {
    this.cableConnection = this.socket.subscribe(
      {
        channel: 'NowPlayingChannel',
        station_id: this.stationId,
        stream_id: this.streamId,
      },
      {
        received: (data) => {
          if (this.profile?.features?.debugLogging) {
            console.log(
              '⚡️ received NowPlayingChannel update for stream monitor, handling...'
            );
          }
          this.lastCacheKey = data?.cache;
          this.fetchNowPlayingData.perform();
        },
      }
    );
  }

  @task
  *updateNowPlaying(nowPlayingRecords) {
    let streams = A();

    nowPlayingRecords.forEach((nowPlaying) =>
      streams.push(...nowPlaying.streams.slice())
    );

    streams = streams.uniq();

    if (streams.length > 1) {
      console.error('more than one stream found');
    } else if (streams.length == 0) {
      return;
    }

    let stream = streams[0];
    this.streamUrl = stream?.allUrls;
    this.streamId = stream?.id;
    this.station = stream.station;
    let nowPlaying = this._selectCurrentNowPlaying(nowPlayingRecords);

    yield this.onUpdate(this.streamUrl, {
      nowPlaying,
      station: this.station,
      stream: stream,
    });
  }

  _selectCurrentNowPlaying(records) {
    // There might be multiple streams in this payload, select the one that's actually playing
    let record;
    if (records?.length === 1) {
      record = records?.[0];
    } else {
      record = records
        ?.filter((s) =>
          s.streams
            .slice()
            .map((s) => s.id)
            .includes(this.streamId)
        )
        .sort((a, b) => {
          a.startsAt < b.startsAt;
        })
        .reverse()[0];
    }

    return record;
  }
}
